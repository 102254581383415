/* eslint-disable @next/next/no-img-element */
import { useCallback, useEffect, useRef } from "react";
import { isMobile } from "react-device-detect";

import Image from "next/image";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/pagination";
import { Autoplay, EffectFade, Pagination } from "swiper/modules";

import styles from "styles/containers/home-banners.module.scss";

export default function HomeBanners({ stopAutoplay, banners = [] }) {
  const sliderRef = useRef();

  useEffect(() => {
    if (stopAutoplay && isMobile) {
      sliderRef?.current?.swiper?.autoplay?.stop();
    }
  }, [stopAutoplay]);

  return (
    <div className={styles.banners}>
      <Swiper
      effect="fade"
        ref={sliderRef}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        loop
        pagination={{
          el: "#swiper-pagination-banners-home",
          clickable: true,
          renderBullet: (_, className) => {
            return `<span class="${className}"></span>`;
          },
        }}
        modules={[Autoplay, Pagination]}
      >
        {banners.map((banner, index) => (
          <SwiperSlide key={index}>
            <div className={styles.banner}>
              <img
                src={`${process.env.NEXT_PUBLIC_CDN_ASSETS}/${banner}`}
                alt=""
              />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}
