import styles from "styles/containers/home.module.scss";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { isDesktop, isMobile } from "react-device-detect";
import { ArrowRight } from "@/shared/components/Icons";
import HomeOptions from "./HomeOptions";
import Banners from "./HomeBanners";
import ReceiveOn from "./ReceiveOnLocal";
import Profile from "../Account/Profile";
import Club from "../Benefits/Club";
import HomeDpwl from "../Home/HomeDpwl";
import { Brand } from "@/shared/core/Brand";
import ReceiveOnLocal from "./ReceiveOnLocal";
import { Loading } from "@/shared/components";


import { DataLayer } from "@/shared/tools/DataLayer";
import { Store } from "@/shared/core/Store";

import classNames from "classnames/bind";
const css = classNames.bind(styles);

export default function Home({ holding }) {
  const [openHomeOptions, setOpenHomeOptions] = useState(isDesktop);
  const [openReceiveOn, setOpenReceiveOn] = useState(false);
  const [onlyBrands, setOnlyBrands] = useState(false);
  const [openProfile, setOpenProfile] = useState(false);
  const [openClub, setOpenClub] = useState(false);
  const [homeDpwl, setHomeDpwl] = useState(false);
  const [loading, setLoading] = useState(true);

  const router = useRouter();
  const isMainHome = router.pathname === "/";
  
  useEffect(() => {
    DataLayer.push({
        event: "page_view",
        page_title: "Home",
    });
  }, [holding])

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  useEffect(() => {
    if (holding) {
      setOpenHomeOptions(isDesktop);
    }
  }, [holding]);

  const processParentMessage = (event) => {
    const { type, data } = event;

    switch (type) {
      case "closeDpwl": {
        setHomeDpwl(false);
        break;
      }
    }
  };

  const tryParseJson = (string) => {
    try {
      return JSON.parse(string);
    } catch {
      return null;
    }
  };

  useEffect(() => {
    window.onmessage = (message) => {
      try {
        const parsedMessage = tryParseJson(message.data);
        if (parsedMessage) {
          processParentMessage(parsedMessage);
        }
      } catch (e) {}
    };
  }, []);

  const banners = Store.getDeviceBanners(isMobile ? "mobile" : "desktop");

  return (
    <div className={styles.home}>
      {homeDpwl && (
        <HomeDpwl open={homeDpwl} close={() => setHomeDpwl(false)} />
      )}

      {loading && (
        <div className={styles.loading}>
          <Loading fullContent fullScreen />
        </div>
      )}

      {!homeDpwl && (
        <div className={css({ contentLoading: loading })}>
          <Banners stopAutoplay={openHomeOptions} banners={banners} />

          <div className={styles.startContainer}>
            <div id="swiper-pagination-banners-home" />

            <div
              className={styles.start}
              onClick={() => setOpenHomeOptions(true)}
            >
              Começar <ArrowRight />
            </div>
          </div>

          {!loading && (
            <>
              <HomeOptions
                open={openHomeOptions}
                onClose={() => isMobile && setOpenHomeOptions(false)}
                onOpenReceive={() => setOpenReceiveOn(true)}
                onOpenProfile={() => setOpenProfile(true)}
                onOpenClub={() => setOpenClub(true)}
                onOpenBrands={() => {
                  setOpenReceiveOn(true);
                  setOnlyBrands(true);
                }}
                holding={holding}
                openDpwl={() => {
                  setHomeDpwl(true);
                }}
              />

              <ReceiveOnLocal
                open={openReceiveOn}
                onClose={() => {
                  setOpenReceiveOn(false);
                  setOnlyBrands(false);
                }}
                onlyBrands={onlyBrands}
                holding={holding}
              />

              <Profile
                open={openProfile}
                onClose={() => setOpenProfile(false)}
              />

              <Club
                open={openClub}
                onClose={() => setOpenClub(false)}
                alreadyDrawer
              />
            </>
          )}
        </div>
      )}
    </div>
  );
}
