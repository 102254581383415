import { useEffect, useLayoutEffect, useState } from "react";

import dynamic from "next/dynamic";

import { ChevronDown, MapMarkerB } from "shared/components/Icons";

import { OrderStorage } from "storage/OrderStorage";

import Addressess from "./Addressess";

import styles from "styles/containers/address-minified.module.scss";
import { Customer } from "shared/core/Customer";

function AddressMinified() {
  const [address, setAddress] = useState(null);
  const [openAddressess, setOpenAddressess] = useState(false);

  const formatAddress = (address) => {
    return (
      address &&
      `${address.address}, ${address.number}, ${address.region} - ${address.city} - ${address.state}`
    );
  };

  const onOrderUpdated = () => {
    const { delivery } = OrderStorage.getOrder();

    setAddress(delivery?.address || null);
  };

  const onCustomerUpdated = () => {
    const customer = Customer.getCustomer();
    const addresses = customer?.extras?.addresses || [];
    const lastSelectedAddress = addresses.find((a) => a.isLastSelected);
    setAddress(lastSelectedAddress || null);
  };

  useLayoutEffect(() => {
    setAddress(OrderStorage.getOrder().delivery?.address);
  }, []);

  useEffect(() => {
    window.addEventListener("orderUpdated", onOrderUpdated);
    window.addEventListener("customerChanged", onCustomerUpdated);

    return () => {
      window.removeEventListener("orderUpdated", onOrderUpdated);
      window.removeEventListener("customerChanged", onCustomerUpdated);
    };
  }, []);

  return (
    <>
      <div
        className={styles.addressMinified}
        onClick={() => setOpenAddressess(true)}
      >
        <div className="d-flex align-center">
          <div className="d-flex align-center">
            <MapMarkerB />
          </div>
          {address && (
            <p>{address.alias ? address.alias : formatAddress(address)}</p>
          )}
          {!address && <p>Endereço não definido</p>}
        </div>
        <div className="d-flex align-center">
          <ChevronDown color="#3D734A" />
        </div>
      </div>

      <Addressess
        open={openAddressess}
        onClose={(status) => setOpenAddressess(status)}
      />
    </>
  );
}

export default dynamic(() => Promise.resolve(AddressMinified), {
  ssr: false,
});
