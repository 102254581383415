/* eslint-disable @next/next/no-img-element */
import { useCallback, useEffect, useRef, useState } from "react";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import { isMobile } from "react-device-detect";
import styles from "styles/containers/brand-banners.module.scss";

import { ChevronLeft, ChevronRight } from "shared/components/Icons";

import { Store } from "@/shared/core/Store";
import { Brand } from "shared/core/Brand";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation, Pagination } from "swiper/modules";

export default function BrandBanners() {
  const [available_banners, setAvailableBanners] = useState(
    Store.getDeviceBanners(isMobile ? "mobile" : "desktop")
  );

  useEffect(() => {
    const updateBanners = () => {
      const banners = Store.getDeviceBanners(isMobile ? "mobile" : "desktop");
      setAvailableBanners(banners);
    };

    updateBanners();

    const interval_id = setInterval(updateBanners, 60000);

    return () => {
      clearInterval(interval_id);
    };
  }, [isMobile]);

  const sliderRef = useRef();

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  }, []);

  if (available_banners?.length === 0) return;

  return (
    <div className={styles.bannerContainer}>
      <Swiper
        ref={sliderRef}
        autoplay={{
          delay: 10000,
          disableOnInteraction: false,
        }}
        loop
        pagination={{
          el: "#swiper-pagination",
          clickable: true,
          renderBullet: (_, className) => {
            return `<span class="${className}"></span>`;
          },
        }}
        modules={[Autoplay, Pagination, Navigation]}
      >
        {available_banners.map((banner, _i) => {
          return (
            <SwiperSlide key={_i}>
              <img
                src={`${process.env.NEXT_PUBLIC_CDN_ASSETS}/${
                  typeof banner === "string" ? banner : banner?.image
                }`}
                alt={Brand.name}
              />
            </SwiperSlide>
          );
        })}
        <div id="swiper-pagination" className={styles.swiperPagination} />
      </Swiper>
      <div onClick={handlePrev} className={styles.prev}>
        <ChevronLeft color="#8c8c8c" />
      </div>
      <div onClick={handleNext} className={styles.next}>
        <ChevronRight color="#8c8c8c" />
      </div>
    </div>
  );
}
