import { useEffect, useState } from "react";

import styles from "styles/pages/home.module.scss";

import Brands from "shared/containers/Home/Brands";
import Banner from "shared/containers/Home/Banner";
import AddressMinified from "shared/containers/Address/AddressMinified";

import { InputSearch } from "shared/components";
import debounce from "lodash.debounce";
import { Customer } from "shared/core/Customer";

import classNames from "classnames/bind";

const cx = classNames.bind(styles);

export function HoldingClubIndex({ holding }) {
  const [selectedCategory, setSelectedCategory] = useState(null);

  const [brands, setBrands] = useState([]);
  const [customerName, setCustomerName] = useState(null);

  useEffect(() => {
    const activeBrands = holding.childs.filter((b) => !!b.active);
    setBrands(
      activeBrands.map((brand) => ({
        name: brand.name,
        alias: brand.alias,
        avatar: brand.avatar,
        address: { city: "" },
        benefits: false,
      }))
    );
  }, []);

  const onSearchBrand = (e) => {
    const searchTerm = (e.target.value || "").toLowerCase();
    const activeBrands = holding.childs.filter((b) => {
      const hasTerm =
        searchTerm.length == 0 || b.name.toLowerCase().indexOf(searchTerm) > -1;
      return !!b.active && hasTerm;
    });

    setBrands(
      activeBrands.map((brand) => ({
        name: brand.name,
        alias: brand.alias,
        avatar: brand.avatar,
        address: { city: "" },
        benefits: false,
      }))
    );
  };

  const onCustomerChanged = async () => {
    setCustomerName(Customer.name);
  };

  useEffect(() => {
    onCustomerChanged();
    Customer.onChange(onCustomerChanged);
    return () => Customer.offChange(onCustomerChanged);
  }, []);

  const banners = holding?.theme?.banners || null;

  return (
    <>
      {!selectedCategory && (
        <>
          {banners && <Banner images={banners || []} />}

          <div
            className={cx({
              homeContainer: true,
              marginTop: banners,
            })}
          >
            <div className={styles.container}>
              <p className={styles.greetings}>
                {customerName ? Customer.getGreetings() : "Boas-vindas!"}
              </p>
              <div className={styles.search}>
                <InputSearch
                  placeholder="Buscar por marca"
                  onChange={debounce(onSearchBrand, 1000)}
                />
              </div>
              <Brands title="Marcas" brands={brands} />
            </div>
          </div>
        </>
      )}
    </>
  );
}
