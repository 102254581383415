import React, { useEffect, useState } from "react";
import styles from "styles/pages/home-dpwl.module.scss";
import { Store } from "../../../shared/core/Store";
import { BucketsAPI } from "../../../api/BucketAPI";
import { Holding } from "@/shared/core/Holding";
import { Brand } from "@/shared/core/Brand";

export default function HomeDpwl({ open }) {
  const [url, setUrl] = useState(null);

  useEffect(() => {
    const fetchUrl = async () => {
      try {
        const storeAlias = Brand.alias || Holding.alias;
        const { url } = await BucketsAPI.getEnvironmentConfigurationsByAlias(
          storeAlias
        );
        const urlParams = url.startsWith("https://") ? url : `https://${url}`;
        setUrl(urlParams);
      } catch (err) {
        console.log(err);
      }
    };

    fetchUrl();
  }, []);

  return (
    open && (
      <div className={styles.home}>
        {url && <iframe className={styles.fullscreenIframe} src={url}></iframe>}
      </div>
    )
  );
}
