import Image from "next/image";

import { isDesktop, isMobile } from "react-device-detect";

import styles from "styles/containers/home-options.module.scss";

import { useEffect, useLayoutEffect, useState } from "react";

import {
  BikeLight,
  GiftLight,
  StoreLight,
  UserLight,
} from "shared/components/Icons";

import { Button, Drawer } from "@/shared/components";
import { ChevronRight, Table } from "@/shared/components/Icons";

import { Brand, BrandEvents } from "@/shared/core/Brand";

import { Holding } from "@/shared/core/Holding";

import useEventListener from "@/shared/hooks/useEventListener";

export default function HomeOptions({
  open,
  onClose,
  onOpenReceive,
  onOpenProfile,
  onOpenClub,
  onOpenBrands,
  holding,
  openDpwl,
}) {

  const name = Brand.name || Holding.name;
  const description = Brand.theme?.description || Holding.theme?.description;

  return (
    <Drawer
      open={open}
      direction={isDesktop ? "right" : "bottom-half"}
      withoutTransition={isDesktop}
      withoutPadding
    >
      <div className={styles.homeOptions}>
        {Brand.avatar && (
          <div className={styles.brandLogo}>
            <Image src={Brand.avatar} alt="" layout="fill" />
          </div>
        )}
        <p className={styles.brandName}>
          {name}
        </p>
        {description ? (
          <p className={styles.brandDescription}>{description}</p>
        ) : null}

        <div className={styles.grid}>
          <div
            className={styles.item}
            onClick={() => {
              openDpwl();
            }}
          >
            <div className={styles.icon}>
              <BikeLight />
            </div>
            <p className={styles.name}>Iniciar</p>
          </div>
          <div className={styles.item} onClick={onOpenReceive}>
            <div className={styles.icon}>
              <Table color="#3D734A" size={16} />
            </div>
            <p className={styles.name}>Pedir na mesa</p>
          </div>
          <div className={styles.item} onClick={onOpenClub}>
            <div className={styles.icon}>
              <GiftLight />
            </div>
            <p className={styles.name}>Acessar clube</p>
          </div>
          <div className={styles.item} onClick={onOpenProfile}>
            <div className={styles.icon}>
              <UserLight />
            </div>
            <p className={styles.name}>Minha conta</p>
          </div>
        </div>
      </div>
    </Drawer>
  );
}
