import Club from "@/shared/containers/Benefits/Club";
import Home from "@/shared/containers/Home/Home";

import { HoldingClubIndex } from "shared/pages/HoldingClubIndex";

import { ServerSideFunction } from "shared/tools/ServerSideFunction";

// export const dynamic = 'force-dynamic';

export const getServerSideProps = (context) => {
  return ServerSideFunction(context);
};

export default function Index({ environmentType, isClub, environment }) {
  if (environmentType == "BRAND") {
    return isClub ? <Club /> : <Home holding={environment} />;
  }

  return isClub ? (
    <HoldingClubIndex holding={environment} />
  ) : (
    <Home holding={environment} />
  );
}
